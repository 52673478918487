import Feature from './feature'
import Video from './video/video'
import sideCutThroughFace from '../img/vhs-tape.jpg'



function BookSuggestions() {

  return (
    <div className="interesting">
    <Feature title="Interesting Video"  background={sideCutThroughFace}>
      
      <Video id="_QTJOAI0UoU" title="Yea">
        <p>Change Your Breath, Change Your Life | Lucas Rockwood | TEDxBarcelona</p>
      </Video>
      <Video id="10J6crRacZg" title="Remarkable">
          <p>Superhumans: The remarkable brain waves of high-level meditators | Daniel Goleman | Big Think</p>
      </Video>
      <Video id="gvpuOBezW0w" title="sine">
        <p>What Do Different Brainwaves Mean?</p>
      </Video>
      <Video id="8XgGctpj_hg" title="of course">
        <p>Nokia Tesla was right</p>
      </Video>
      <Video id="p9UC0chfXcg" title="Water, Cells, and Life">
        <p>We need the sunshine.</p>
      </Video>
      <Video id="wDZgzsQh0Dw" title="Music Medicine: Sound At A Cellular Level">
        <p>Low Frequencies will heal you</p>
      </Video>
     
    </Feature>
    </div>
  )
}

export default BookSuggestions;
