import './breathRegulator.css'

import BeepToggle from './beepToggle'

function Link(props) {
  let L = <>{props.children}</>
  if(props.link) {
    L = <a href={props.href}>{props.children}</a>
  }
  return L
}

function ListDisplay(props) {
  return <div style={{ "marginTop":  "30px"}}><p style={{ "paddingTop" :  "11px", "paddingBottom" :  "11px"}}>{props.title}</p></div>
}


function BreathRegulator(props) {
  
  const href = '/breath/' + props.id

  return (
      <div className="breath_regulator">
 <Link link={props.link} href={href}>
        <div id="progressbar">
          <div style={{ "width": props.percent + "%"}}>&nbsp;</div>
        </div>
       
        <div className="co">
        <h3>{props.direction}</h3>
       
        <ListDisplay title={props.children} />
     
         
        </div>
        </Link>
      
      </div>
  )
}

export default BreathRegulator;
