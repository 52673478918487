import sideCutThroughFace from '../img/sideCutThroughFace.jpg';
import Feature from '../components/feature'

function Nose() {
    return (
        <Feature title="Have you tried using your nose for breathing?" background={sideCutThroughFace} breaking={true}>
          <h2>Start using your nose to breath today and gain the following health benfits for FREE.</h2>
          <ul>
            <li>Produce Nitric Oxide to kill viruses</li>
            <li>Warm the air for your lungs</li>
            <li>Filter the air</li>
            <li>Moisten the air</li>
            <li>Trigger your diaphram to move</li>
            <li>Plus many many more ...</li>
          </ul>
          {/* <div className="container">
            <div><p style={{ "textAlign" : "center" }}>So are you telling me that if I just breath through my nose habitually to a nice slow rhythm of 5.5 seconds that I'll live forever!</p></div>
            <div><p style={{ "textAlign" : "center", "marginTop" : "22px" }}>No, your body will still run it's course, but this simple change in breathing will change your life in ways you would not think was possible from something so small.</p></div>
          </div> */}
         
          
          {/* <div><h3>Links</h3><a className="link" href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7200356/">Could nasal nitric oxide help to mitigate the severity of COVID-19?</a></div> */}
        </Feature>
      )
}

export default Nose