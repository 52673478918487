import create from 'zustand'

// https://developers.google.com/youtube/iframe_api_reference#seekTo
const status = {
    UNSTARTED : -1,
    ENDED: 0,
    PLAYING: 1,
    PAUSED: 2,
    BUFFERING : 3,
    CUED: 5
}

function percentage(num, per)
{
  return (per/num)*100;
}


export function calculateTimeStamps(timestamps, duration) {
    console.log("WHAT", timestamps)
    let last = null
    let result = []

    let runningOrder = Object.keys(timestamps).sort(function(a, b) {
        return a - b;
    })

    Object.keys(timestamps).sort(function(a, b) {
        return a - b;
    })
    let marginLeft = 0
    
    runningOrder.map((key) => {
        let t = timestamps[key]
     
        if(last !== null) {
            let p = percentage(duration, t.timestamp - last)
            result.push({
                marginLeft: marginLeft,
                percent: p,
                start: last, 
                end: t.timestamp,
                text: t.text
            })
            marginLeft += p
        } 
        last = t.timestamp
    })
    return result
}

export const useVideoStore = create((set,get) => ({
  status: status.UNSTARTED,
  percent: 0,
  now: 0,
  duration: null,
  timestamp: {},
  interval: null,
  tracker: 0,
  timestampId: null,
  startTracker: () => {
    const i = setInterval(() => {
       const tr = get().tracker
       set( { tracker : tr + 100  })
    }, 100)
    set( { interval : i })
  },
  stopTracker: () => {
    const i = get().interval
    const tracker = get().tracker
    let timestamp = get().timestamp
    let keys =  Object.keys(timestamp)
    let lastId = keys[keys.length-1]
    let last = timestamp[lastId]
    last.duration = tracker
    timestamp[lastId] = last
   
    clearInterval(i)
    set( { tracker, timestamp })
  },
  cancelTracker:() => {
    const i = get().interval
    clearInterval(i)
  },
  addTimeStamp: (ts, text) => {
    const t = get().timestamp
    t[ts] = {
        timestamp: ts,
        text
    }

    console.log(t)
    
    set( { timestamp : t, timestampId: ts })
  }
 // setPlayfrom: (timestamp) => { now = timestamp }
}))

let videoPlayer

export async function onReady(player) {
    videoPlayer = player.current.internalPlayer
}

export async function videoState(event) {

    switch(event.data) {
        case status.PLAYING:
            if(useVideoStore.getState().duration === null) {
                const duration = await videoPlayer.getDuration()
                useVideoStore.setState({
                    duration : duration
                })
                let addTimeStamp =  useVideoStore.getState().addTimeStamp
                addTimeStamp(0, "START")
                addTimeStamp(duration,"END")
            }
            break
        case status.PAUSED: 
            // const timestamp = await videoPlayer.getCurrentTime()
            // let addTimeStamp =  useVideoStore.getState().addTimeStamp
            // addTimeStamp(timestamp)
              //  console.log(useVideoStore.getState())
          break;
        default:
            console.log( await videoPlayer.getDuration())
            console.log(event.data + " uncaptured video state change")
    }
}

export function playVideoFrom(now) {
    videoPlayer.seekTo(now)
}

export function stopTracker(id) {
    let stopTracker =  useVideoStore.getState().stopTracker
    stopTracker(id)
}

export async function addMoment(text) {
    const timestamp = await videoPlayer.getCurrentTime()
    let addTimeStamp =  useVideoStore.getState().addTimeStamp
    addTimeStamp(timestamp, text)
    let st =  useVideoStore.getState().startTracker
    st()
}

export function addPlayer(player) {
    videoPlayer = player
}   

export default addPlayer