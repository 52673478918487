import create from 'zustand'

const status = {
  HOLD : "-HOLD-",
  INHALE : "INHALE",
  EXHALE : "EXHALE"
}

export const useBreathStore = create(set => ({
  status: status.INHALE,
  percent: 0,
  now: 0,
  max: 5000,
  hold: {
    [status.INHALE] : 1000,
    [status.EXHALE] : 1000
  }
}))

function percentage(num, per)
{
  return (per/num)*100;
}


export function startBreathing(intervalLength) {


    const interval = setInterval(() => {
      clearInterval(interval)
      let { currDirection, curCount, curPercent }  = slowBearthRegulator(useBreathStore.getState().status, useBreathStore.getState().now, 100, useBreathStore.getState().max)
      let currInterval = 100
      if(curPercent === 0 || curPercent === 100) {    
         currInterval = useBreathStore.getState().hold[currDirection]
         currDirection = status.HOLD
      } 

      useBreathStore.setState({ 
        percent : curPercent,
        status : currDirection,
        now : curCount 
      })

      startBreathing(currInterval)

    }, intervalLength)
}

function slowBearthRegulator(direction, count, intervalLength, durationInMiliseconds) {
  
    let currDirection = direction
    let curCount = count
    
    if(curCount >= durationInMiliseconds) {
        currDirection = "EXHALE"
    } 
      
    if(curCount <= 0) {
        currDirection = status.INHALE
    }
  
    if(currDirection === "EXHALE") {
       curCount = curCount - intervalLength
    } else {
       curCount = curCount + intervalLength
    }

    let curPercent = Math.round(percentage(durationInMiliseconds, curCount))
    
    return {
      currDirection, 
      curCount,
      curPercent
    }
  
  }

export default startBreathing