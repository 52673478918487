import './logo.css';

function Logo() {
  return (
    <div id="logo">
      <div className="Aligner">
          <div className="Aligner-item"><h1 style={{"font-family":'MLB Tuscan Regular'}}>SLOW<br />NOSE<br />.COM</h1></div>
      </div>
    </div>
  )
}

export default Logo;
