import './feature.css';

function Feature(props) {

  let img = null
  if(props.background) {
    img = <img src={props.background} alt={props.title + " Image"} />
  }
  return (
    <div className="feature">
      {img}
        <h1>{props.title}</h1>
        <div className="children"> 
            {props.children}
        </div>
    </div>
  )
}

export default Feature;
