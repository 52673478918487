import React, { useRef, useState } from 'react';
import './video.css'
import { useVideoStore, videoState, onReady, calculateTimeStamps, playVideoFrom, addMoment, startTracker, stopTracker } from '../../lib/videoSlicer';

import YouTube from 'react-youtube';

function ShowVideo(props) {
  let href = '/video/'  + props.id
  return <a href={href}>{props.title}</a>
}

function Timestamp(props) {
  let all = props.timestamp
  //all.push(props.duration)
  let duration = useVideoStore.getState().duration
  var fullMinutes = Math.floor(duration / 60);
  var fullSeconds = duration - fullMinutes * 60;
 // console.log('all', all)
  let slots = calculateTimeStamps(all,duration)

  return <div id="timeline" style={{ 'background': '#e1e1e1', 'margin' : 0, 'padding' : 0, 'width' : '100%' }}>
 {/*    <div onClick={() => {playVideoFrom(0)}} className="timeslot" style={{ 'marginLeft': 0, 'background': 'red', 'width' : '100%', 'textAlign': 'center' }}>The Title of the Video - {fullMinutes}:{fullSeconds}</div> */}
    {slots.map((slot, i) => {
      let percent = slot.percent + '%'
      let ml = slot.marginLeft + "%"
      var minutes = Math.floor(slot.start / 60);
      var seconds = slot.start - minutes * 60;
      return <div key={i} onClick={() => {playVideoFrom(slot.start)}} className="timeslot" style={{ 'marginLeft': ml, 'background': 'red', 'width' : percent }}>{minutes}:{seconds.toFixed(0)} {slot.text}</div>
    })}
  </div>
}

function Slice() {

  const textInput = useRef(null);
  const durationInput = useRef(null);
  const [slotDuration, saveSlotDuration] = useState(null)

  function handleClick() {
    addMoment(textInput.current.value)
    saveSlotDuration(true)
  }

  function saveDuration() {
    stopTracker()
  }

  const tracker = useVideoStore().tracker

  return <div>
  <input
    type="text"
    ref={textInput} />
  <input
    type="button"
    value="Focus the text input"
    onClick={handleClick}
  />
  {slotDuration !== null ? 
  <>
  <input
    type="text"
    ref={durationInput}
    value={tracker} />
    <input
    type="button"
    value="HERE"
    onClick={saveDuration}
  />
  </> :  null}
  </div>
}

function VideoBox(props) {
  let edit = false

  // <Timestamp duration={props.duration} timestamp={props.timestamp} />

  return <div id="video_box" className="video-responsive">
    <div className="video">{props.children}</div>
    {edit ? <Slice /> :  null}
    </div>
   
}

function Video(props) {

 const show = props.show;

  const opts = {
    width: 514,
    height: 289,
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
     
    },
  };

  const all = useVideoStore()
  const player = useRef();

  return (
      <div title={props.title} background={props.cover}>
        <div href={props.buy} className="Video">
          {props.children}
          <ShowVideo  {...props} show={show} />
          {show ? <>
            
            <VideoBox duration={all.duration} timestamp={all.timestamp}>
            <ShowVideo {...props} show={show} />
            <YouTube  ref={player} onReady={() => { onReady(player)} } onStateChange={(e) => { videoState(e) }} videoId={props.id} opts={opts} />
            </VideoBox></> : null}
        </div>
      </div>
    )
}

export default Video;