import './tabs.css'
import create from 'zustand'

export const useTabStore = create((set,get) => ({
    open: 0,
    setOpen: (index) => {
        set({"open" : index})
    }
}))

export function Tabs(props) {

    const setOpen = useTabStore(state => state.setOpen)
    const open = useTabStore(state => state.open)

    if(props.index){
        setOpen(props.index)
    }
    
    return <div className="tabs">
        <ul>
            {props.children.map((one, index) => {
                let selected = index === open ? "selected" : "unselected"
                return  <li key={index} className={selected} onClick={() => setOpen(index)}>{one.props.title}</li>
            })}
        </ul>
        <div className="tab-content">
            {props.children[open]}
        </div>
    </div>
}

export function Tab(props) {
    return <>{props.children}</>
}

export default Tabs