import create from 'zustand'
import beep from '../../lib/beep'

const audioCtx = new (window.AudioContext || window.webkitAudioContext || window.audioContext);


const status = {
  HOLD : "HOLD",
  INHALE : "INHALE",
  EXHALE : "EXHALE"
}

export const useBeep = create(set => ({
  duration: 500,
  frequency:  666,
  volume: 0,
  tone: 'sine',
  tones: ['sine', 'square', 'sawtooth', 'triangle', 'custom'],
  toggleVolume: (v) => { set({ volume: v }) }
}))

function playBeep(audioCtx, duration, frequency, volume, tone) {
  console.log(new Date(), "BEEP", duration, frequency, volume, tone)
  beep(audioCtx, duration, frequency, volume, tone)
}

function Volume(props) {
  const toggleVolume = useBeep(state => state.toggleVolume)
  return  <>
      <input type="radio" name="volume" checked={props.volume} onClick={() => { toggleVolume(1) }} />On
      <input type="radio" name="volume" checked={!props.volume}  onClick={() => { toggleVolume(0) }} />Off
   </>
}
  
function BeepToggle() {

  let tone = useBeep(state => state.tone)
  let frequency = useBeep(state => state.frequency)
  let volume = useBeep(state => state.volume)
  let duration =  useBeep(state => state.duration)

    return <div id="beep">
        <Volume volume={volume} />
        <input type="radio" name="sine" />Sine
        <input type="radio" name="square" />square
        <input type="radio" name="sawtooth" />Sawtooth
        <input type="radio" name="triangle" />triangle
        <input type="radio" name="custom" />custom
        <button onClick={() => { playBeep(audioCtx, duration, frequency, volume, tone) }}>Play</button>
    </div>
  }

  export default BeepToggle