import Feature from '../feature'
import './book.css'
function Book(props) {

    return (
      <Feature title={props.title} background={props.cover}>
        <a href={props.buy} className="book">
        <h3>{props.author}</h3>
        {props.children}
        </a>
      </Feature>
    )
  }

  export default Book;