import Feature from '../components/feature'
import Video from '../components/video/video'
import { Tab, Tabs } from '../components/tabs/tabs'

// https://www.wimhofmethod.com/controlling-the-autonomic-nervous-system

function WimHoff() {
    return (
        <Feature title="Wim Hoff Technique">
          <h2>Controlling the autonomic nervous system</h2>
           <p>Wim Hoff has discovered a breathing hack that will transform your physiology.  Give this a go every morning and feel the difference it makes for youself.</p>
           
           <Video id="nzCaZQqAs9I" title="Wim's breathing tutoral" show={true} />

           <p>Here are some videos so you can help you have a go yourself.</p>

           <Tabs>
              <Tab title="beginner"><Video id={"0BNejY1e9ik"} title="beginner breathing" show={true} /></Tab>
              <Tab title="longer"><Video id={"tybOi4hjZFQ"} title="longer breath hold" show={true} /></Tab>
              <Tab title="serious"> <Video id="avZ_5Cot0wY" title="5 rounds really makes a difference" show={true} /></Tab>
           </Tabs>

           
    
           
           
          

           <p>Here a doctor will explain what is happening when you perform this technique.</p>
           
           <Video id={"S-r35dBbAB8"} title="Wim Method Explained by a doctor" show={true} />

           <p>Watch Russell Brand have go at the breathing method on his podcast.</p>

           <Video id={"QNJ1Volf1D4"} title="Russell Brand breathing Wim style" />
           
        </Feature>
      )
}

export default WimHoff