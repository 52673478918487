import './App.css';
import 'normalize.css'

import React, { useEffect } from 'react';
import Header from './components/header'
import BookSuggestions from './components/bookSuggestions'
import BreathingVideos from './components/breathingVideos'
import Nose from './pages/nose'
import WinHoff from './pages/wimhoff'
import Video from './pages/video';
import Breath from './pages/breath'

function resizeFeatures() {
  let allChildren = document.body.querySelectorAll(".article > .feature");

  allChildren.forEach((item, index) => {
    
    console.log(index, item)
  });

}

function Home() {
  let resize = true
  useEffect(() => {
    if(resize === true) {
      resize = false
      resizeFeatures()
    }
  })
  return <>
      <Header />
      <div className="container">
        <div className="article"><Nose /></div>
        <div className="article"><WinHoff /></div>
        <div className="article"><BreathingVideos /></div>
        <div className="article"><BookSuggestions /></div>
      </div>
  </>
}


function loadPage(pathname) {
  const s = pathname.split('/')
  return {
    page: s[1],
    id: s[2]
  }
}


function App() {

  let Page = Home 
  let route = loadPage(window.location.pathname)
  switch(route.page) {
    case 'video':
      Page = Video
      break;
    case 'breath':
      Page = Breath
      break;
    default:
      console.log(route)
  }
    
  return <Page route={route} />
 
}

export default App;
