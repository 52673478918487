import Feature from './feature'
import Book from './book/book'


function BookSuggestions() {

  return (
    <Feature title="Book Suggestions">
      <div className="container">
      <Book author="James Nestor" title="Breath" cover="https://images-na.ssl-images-amazon.com/images/I/41aYi-hfFOL._SX324_BO1,204,203,200_.jpg" buy="https://www.amazon.co.uk/Breath-New-Science-Lost-Art/dp/0241289122">
        <p>If you only read one book in your entire life, make it this book</p>
      </Book>
      <Book author="Patrick McKeown" title="Oxygen Advantage" cover="https://images-na.ssl-images-amazon.com/images/I/51c3z3qYCYL._SX325_BO1,204,203,200_.jpg" buy="https://www.amazon.co.uk/Oxygen-Advantage-scientifically-breathing-revolutionise/dp/0349406693/ref=sr_1_1?crid=1KOUU224U501A&dchild=1&keywords=oxygen+advantage+book&qid=1627669699&sprefix=oxygen+ad%2Caps%2C183&sr=8-1">
        <p>This book is not just for sports people who want to improve their fitness and training</p>
      </Book>
      <Book author="Wim Hof" title="The Wim Hof Method" cover="https://images-na.ssl-images-amazon.com/images/I/51BU08+2YRL._SX337_BO1,204,203,200_.jpg" buy="https://www.amazon.co.uk/Wim-Hof-Method-Potential-Transcend/dp/1846046297/ref=sr_1_1?crid=13ROHDHOBS00X&dchild=1&keywords=wim+hof+method&qid=1627669815&sprefix=wim+hof%2Caps%2C200&sr=8-1">
        <p>Once you start beathing correctly your mind will be become clearer and more focused.  Now it's time to see what power we all posses inside, read this book and change you're life</p>
      </Book>
      </div>
    </Feature>
  )
}

export default BookSuggestions;
