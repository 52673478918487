import React, { useEffect } from 'react';
import { useBreathStore, startBreathing } from '../fivePointFive/slowBreathRegulator'
import { useFivePointFive, startTitles } from '../fivePointFive/titlesStore'
import BreathRegulator from '../component/breathRegulator'
 

function FivePointFive(props) {

  useEffect(() => {
    startBreathing(100)
    startTitles(0)
  },[])
  
  let percent = useBreathStore(state => state.percent)
  let count = useBreathStore(state => state.now)
  let direction =  useBreathStore(state => state.status) 
  let title = useFivePointFive(state => state.title)
  let link = props.link ? props.link : false

  return <BreathRegulator link={link} id="fivepointfivesecondsbreath" direction={direction} count={count} percent={percent}>
      {title}
  </BreathRegulator>
      
}

export default FivePointFive;
