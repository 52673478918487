
import FivePointFive from '../breath/fivePointFive/firePointFive'
import Logo from '../components/logo/logo'

function Header() {

  return (
    <header className="container">
      <Logo />
      <FivePointFive link={true} />
    </header>
  )
    
}

export default Header;
