import create from 'zustand'

const status = {
  START : 0
}

const titles =  ['Follow this slow breathing pattern','blood flow to your brain will increased','systems in your body enter a state of coherence','functions of heart','circulation, and nervous system','are coordinated','to peak efficiency']

export const useFivePointFive = create(set => ({
  index: 0,
  title: ""
}))


export function startTitles(intervalLength) {

    const interval = setInterval(() => {
      clearInterval(interval)
      let { currInterval, currIndex, curTitle }  = tileSelector(useFivePointFive.getState().index)

      useFivePointFive.setState({ 
        index : currIndex,
        title: curTitle
      })

      startTitles(currInterval)

    }, intervalLength)
}

function tileSelector(index) {
  
    let title = titles[index]
    index++
    let currInterval = 1111 / (index / 4)
    if(index >= titles.length) {
        index = 0
        currInterval = 3000
    }

    

    return {
        curTitle: title,
        currIndex: index,
        currInterval
    }
  
  }

export default startTitles